@import"../../assets/Scss/media-queries.scss";

.login {
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;
}

.left_img,
.right_img {
  height: 70vh;

  @include lt-lg {
    width: 0px;
  }
}

.login_card {
  max-width: 500px;
  width: 500px;
  padding: 5rem 3rem;
  margin: 5px;

  background-color: white;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.02);
  border: 0.0625rem solid rgb(233, 233, 233);
  border-radius: 0.5rem;

  @include lt-lg {
    max-width: 500px;
    width: 500px;
  }

  @include lt-md {
    max-width: 450px;
    width: 450px;
  }
  @include lt-sm {
    max-width: 350px;
    width: 350px;
  }
}

.title {
  text-align: center;
  padding: 0px 0px 20px 0px;
}

.label {
  font-size: 16px;
  padding: 5px 0px;
}

.remember {
  margin-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.forget_pass {
  cursor: pointer;
}

.forget_pass:hover {
  text-decoration: underline;
}
