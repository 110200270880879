:root {
  --main-ccc: #8DC040;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--main-ccc);
  border-radius: 50px;
}
